// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NosFantomesV2-module--CarrouselWrapper2--nE8KM";
export var DescriptionWrapper = "NosFantomesV2-module--DescriptionWrapper--J+jnU";
export var DownloaderWrapper = "NosFantomesV2-module--DownloaderWrapper--wMToi";
export var EventWrapper = "NosFantomesV2-module--EventWrapper--sG1jH";
export var ImageWrapper = "NosFantomesV2-module--ImageWrapper--LhN28";
export var ImagesWrapper = "NosFantomesV2-module--ImagesWrapper--AiIjJ";
export var MobileTile = "NosFantomesV2-module--MobileTile--UdX1G";
export var PdpWrapper = "NosFantomesV2-module--PdpWrapper--2Fh6w";
export var PhotosWrapper = "NosFantomesV2-module--PhotosWrapper--ud6BL";
export var TitleWrapper = "NosFantomesV2-module--TitleWrapper--LRMPZ";
export var Wrapper = "NosFantomesV2-module--Wrapper--qOHNb";